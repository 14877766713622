<template>
  <div class="agreements">
    <div class="agreements__filter">
      <p>Год</p>
      <div class="agreements__select">
        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="years"
          :canClear="false"
        />
      </div>
      <ButtonComponent class="gradient-bg button-redesign--gradient-bg" @click="loadAgreements"
        >Применить</ButtonComponent
      >
    </div>

    <div class="table-container-new">
      <table class="stockTable agreement-table">
        <thead>
          <tr>
            <th><p>№</p></th>
            <th><p>Форма соглашения</p></th>
            <th><p>Наименование</p></th>
            <th><p>Сумма (млн. руб)</p></th>
            <th><p>Статус реализации</p></th>
          </tr>
        </thead>
        <tbody v-if="passportListOfAgreements.length">
          <tr v-for="(agreement, index) in passportListOfAgreements" :key="index">
            <td>
              <p>{{ page?.firstItemNumber + index }}</p>
            </td>
            <td>
              <p>{{ agreement.agreementsForm }}</p>
            </td>
            <td>
              <p>{{ agreement.name }}</p>
            </td>
            <td>
              <p class="agreement-table__sum">{{ formatNumber(agreement.sum / THOUSAND, 1) }}</p>
            </td>
            <td>
              <div class="agreement-table__status">
                <span class="agreement-table__status-circle" :class="resolveStatusClass(agreement.status)"></span>
                <p>{{ agreement.status }}</p>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="5" class="agreements__no-data">Данные отсутствуют</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="agreements__pagination">
      <PaginationRedesigned
        v-if="page.pagesTotal > 1"
        :loadingMore="loadingPagination"
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event)"
        @more="onAddMoreData($event)"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';
  import { AgreementStatus } from '../../utils/constants';

  export default {
    name: 'Agreements',
    mixins: [text],
    components: { PaginationRedesigned, Multiselect, ButtonComponent },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      page: {
        page: 1,
        pageSize: 5,
        pagesTotal: 1,
        firstItemNumber: 1,
      },
      request: {
        page: 1,
        pageSize: 5,
      },
      loadingPagination: false,
      THOUSAND: constants.THOUSAND,
    }),
    computed: {
      ...mapGetters({
        passportListOfAgreements: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportListOfAgreements,
      }),
    },
    watch: {
      regionId: {
        handler() {
          this.loadData();
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions({
        getPassportAgreements: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportAgreements,
        getMorePassportAgreements: ECONOMIC_RELATIONS_ACTIONS_NAME.getMorePassportAgreements,
      }),
      async loadData() {
        this.year = this.years[this.years.length - 1].value;
        await this.loadAgreements();
      },
      async loadAgreements() {
        const response = await this.getPassportAgreements({
          regionId: this.regionId,
          year: this.year,
          ...this.request,
        });

        if (response.success) {
          this.setMetadata(response.data);
        }
      },
      setMetadata(metadata) {
        this.page.pageNumber = metadata.pageNumber;
        this.page.pageSize = metadata.pageSize;
        this.page.pagesTotal = metadata.pagesTotal;
        this.page.firstItemNumber = metadata.firstItemNumber;
      },
      resolveStatusClass(status) {
        if (status === AgreementStatus.PLAN_FOR_REALIZE) {
          return 'bg-yellow';
        }
        if (status === AgreementStatus.REALIZE) {
          return 'bg-yellow';
        }
        if (status === AgreementStatus.STOPPED) {
          return 'bg-red';
        }
        if (status === AgreementStatus.DONE) {
          return 'bg-green';
        }
        return 'bg-grey';
      },
      onPageChange(pageNumber) {
        this.request.page = pageNumber;
        this.loadAgreements();
      },
      async onAddMoreData() {
        this.loadingPagination = true;

        this.request.page += 1;

        const response = await this.getMorePassportAgreements({
          regionId: this.regionId,
          year: this.year,
          ...this.request,
        });

        if (response.success) {
          this.setMetadata({ ...response.data, firstItemNumber: 1 });
        }
        this.loadingPagination = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .agreements {
    &__filter {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
      p {
        font: $fira-16;
      }
    }
    &__select {
      width: 150px;
    }

    &__no-data {
      font: $fira-16;
      text-align: center;
      margin-top: 20px;
    }

    &__pagination {
      margin-top: 24px;
    }
  }
  .agreement-table {
    &__sum {
      min-width: 120px;
    }
    &__status {
      display: flex;
      align-items: center;
    }
    &__status-circle {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .bg-grey {
    background: $grey;
  }
</style>
