<template>
  <div class="passport-economic-relations">
    <HeaderBlock
      headerTitle="паспорт внешнеэкономических связей"
      headerSelectTitle="Субъект РФ:"
      :headerTabs="headerTabs"
      :selectOptions="regions"
      :countryExternal="regionId"
      :activeTab="activeTab"
      :activeTabId="activeTabId"
      :selectTypeTab="selectTypeTab"
      :canClearSelect="false"
      @changeCountry="changeRegion"
      @onOpenPresentationDialog="onOpenPresentationDialog"
      @switchTab="setActiveTypeTab"
      @switchTabLVLTwo="selectTab"
    />

    <div class="container-page big">
      <KeyIndex
        v-if="selectTypeTab === foreignEconomicRelationTabs.KEY_INDEX.id"
        :regionId="regionId"
        :years="yearOptions"
      />
      <TradeTurnover
        v-if="selectTypeTab === foreignEconomicRelationTabs.TRADE_TURNOVER.id"
        :regionId="regionId"
        :years="yearOptions"
      />
      <CommonInfo
        v-if="selectTypeTab === foreignEconomicRelationTabs.COMMON_INFO.id"
        :regionId="regionId"
        :years="yearOptions"
      />
      <PartnerCountries
        v-if="selectTypeTab === foreignEconomicRelationTabs.COUNTRY_PARTNERS.id"
        :regionId="regionId"
        :years="yearOptions"
      />
      <Events
        v-if="selectTypeTab === foreignEconomicRelationTabs.EVENTS.id"
        :regionId="regionId"
        :years="yearOptions"
      />
      <ResponsibleOrgan
        v-if="selectTypeTab === foreignEconomicRelationTabs.VES_ORGANIZATION.id"
        :regionId="regionId"
        :years="yearOptions"
      />
      <Agreements
        v-if="selectTypeTab === foreignEconomicRelationTabs.AGREEMENTS.id"
        :regionId="regionId"
        :years="yearOptions"
      />
    </div>
  </div>
  <ModalComponent
    title="Скачать презентацию"
    v-model="presentationDialogState"
    headType="redesigned"
    bodyType="redesigned"
    @close="presentationDialogState = false"
  >
    <div class="form__body">
      <div class="form__body-inputBlock mb-3 pb-3 form-group required">
        <p class="form-label">Субъект</p>
        <Multiselect
          placeholder="Укажите субъект"
          label="text"
          :searchable="true"
          v-model="presentationRegionId"
          valueProp="id"
          track-by="text"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="regions"
        />
      </div>
      <div class="passport-economic-relation-modal-input form__body-inputBlock mb-3 pb-3 form-group required">
        <p class="form-label">Год</p>
        <!-- <ComponentInput placeholder="Укажите год" type="number" v-model="presentationYear" required /> -->
        <Multiselect
          label="label"
          :searchable="true"
          v-model="presentationYear"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          openDirection="top"
          :options="yearOptions"
          :canClear="false"
        />
      </div>
      <div class="form__body-inputBlock">
        <ButtonStock title="Скачать" @click="loadPresentation" />
      </div>
    </div>
  </ModalComponent>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import HeaderBlock from '@/modules/countryАnalytics/components/HeaderBlock.vue';

  import Api from '../api/passportRelations';
  import Agreements from '../components/passportRelations/Agreements';
  import CommonInfo from '../components/passportRelations/CommonInfo';
  import Events from '../components/passportRelations/Events';
  import KeyIndex from '../components/passportRelations/KeyIndex';
  import PartnerCountries from '../components/passportRelations/PartnerCountries.vue';
  import ResponsibleOrgan from '../components/passportRelations/ResponsibleOrgan';
  import TradeTurnover from '../components/passportRelations/TradeTurnover';
  import { BASE_VES_ACTIONS_NAME, BASE_VES_GETTERS_NAME } from '../store/base/name';
  import { ForeignEconomicRelationTabs } from '../utils/constants';

  import store from '@/store';
  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    components: {
      HeaderBlock,
      KeyIndex,
      CommonInfo,
      Events,
      ResponsibleOrgan,
      Agreements,
      PartnerCountries,
      ModalComponent,
      ButtonStock,
      Multiselect,
      TradeTurnover,
    },
    data() {
      return {
        regionId: this.$route.query?.regionId ?? null,
        regions: [],
        activeTab: 'Рейтинг эффективности',
        activeTabId: 1,
        selectTypeTab: 1,

        presentationRegionId: null,
        presentationYear: null,
        presentationDialogState: false,

        foreignEconomicRelationTabs: ForeignEconomicRelationTabs,
      };
    },
    computed: {
      ...mapGetters({
        yearOptions: BASE_VES_GETTERS_NAME.getYearOptions,
        regionsList: DIRECTIVES_GETTERS_NAME.getRegionsSearchIdList,
      }),
      headerTabs() {
        const ForeignEconomicRelationTabs = this.foreignEconomicRelationTabs;

        return [
          {
            id: ForeignEconomicRelationTabs.KEY_INDEX.id,
            text: ForeignEconomicRelationTabs.KEY_INDEX.text,
          },
          { id: ForeignEconomicRelationTabs.TRADE_TURNOVER.id, text: ForeignEconomicRelationTabs.TRADE_TURNOVER.text },
          { id: ForeignEconomicRelationTabs.COMMON_INFO.id, text: ForeignEconomicRelationTabs.COMMON_INFO.text },
          {
            id: ForeignEconomicRelationTabs.COUNTRY_PARTNERS.id,
            text: ForeignEconomicRelationTabs.COUNTRY_PARTNERS.text,
          },
          { id: ForeignEconomicRelationTabs.EVENTS.id, text: ForeignEconomicRelationTabs.EVENTS.text },
          {
            id: ForeignEconomicRelationTabs.VES_ORGANIZATION.id,
            text: ForeignEconomicRelationTabs.VES_ORGANIZATION.text,
          },
          { id: ForeignEconomicRelationTabs.AGREEMENTS.id, text: ForeignEconomicRelationTabs.AGREEMENTS.text },
        ];
      },
    },
    async created() {
      await this.getRegionsSearchId();

      this.regions = this.regionsList.map(({ id, name, regionCodes }) => ({
        id,
        text: name,
        code: regionCodes,
      }));

      this.checkUserRole();

      const yearResponse = await this.getYearLast();
      if (yearResponse.success) {
        this.presentationYear = this.yearOptions[this.yearOptions.length - 1].value;
      }
    },
    methods: {
      ...mapActions({
        getYearLast: BASE_VES_ACTIONS_NAME.getYearLast,
        getRegionsSearchId: DIRECTIVES_ACTIONS_NAME.getRegionsSearchId,
      }),

      checkUserRole() {
        const userRegionRfIds = store._state?.data?.auth?.oidc?.profile?.employee.regionRfIds ?? [];
        const userRoleNames = store._state?.data?.auth?.oidc?.profile?.roleNames;
        const isSubjectRfRole = userRoleNames === Constants.userRoleNames.subjectRF;
        if (!userRegionRfIds?.includes(String(this.regionId)) && isSubjectRfRole) {
          this.regionId = userRegionRfIds[0];
          this.$router.replace({ name: 'PassportForeignEconomicRelations', query: { regionId: this.regionId } });
        }

        // Для роли "Субъект РФ" доступен только его субъект
        if (isSubjectRfRole) {
          this.regions = this.regions.filter(({ id }) => userRegionRfIds?.includes(id));
        }
      },

      changeRegion(region) {
        this.regionId = region;
        this.$router.push({ query: { regionId: this.regionId } });
      },

      setActiveTypeTab(id) {
        this.selectTypeTab = id;
      },
      selectTab(tab) {
        this.activeTab = tab?.text;
        this.activeTabId = tab?.id;
      },

      onOpenPresentationDialog() {
        this.presentationRegionId = this.regionId;
        this.presentationYear = null;
        this.presentationDialogState = true;
      },
      loadPresentation() {
        Api.createPresentation({ regionId: this.presentationRegionId, year: this.presentationYear })
          .then(() => {
            this.presentationDialogState = false;
          })
          .catch(() => {
            Constants.alert.fire('Загрузка', 'Ошибка скачивания', 'error');
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .passport-economic-relation-modal-input {
    input {
      border-radius: 4px !important;
    }
  }
</style>
