<template>
  <div class="events">
    <div class="mb-20">
      <div class="events__header-wrapper">
        <p class="events__header">
          Запланированные мероприятия <span>{{ getPassportCurEventsCount }}</span>
        </p>
        <CustomLink :to="{ name: 'EventsVES', query: { regionId: regionId } }"> График мероприятий ВЭС </CustomLink>
      </div>

      <div class="events__cards">
        <EventCard v-for="event in getPassportCurEvents.events" :key="event.id" :event="event" theme="fill" />
      </div>
      <div class="d-flex justify-content-center">
        <ButtonComponent v-if="isShowMoreCurrentEvent" class="events__show-btn" @click="loadCurrentEvents">
          Показать еще
          <IconComponent name="plus-mini" class="events__show-btn-icon" />
        </ButtonComponent>
      </div>
    </div>

    <div>
      <div class="events__header-wrapper">
        <p class="events__header">
          Прошедшие мероприятия <span>{{ getPassportPrevEventsCount }}</span>
        </p>
        <div class="events__year-select">
          <Multiselect
            class="select-country-analytic"
            label="label"
            :searchable="true"
            v-model="year"
            valueProp="value"
            track-by="label"
            noResultsText="Ничего не найдено."
            noOptionsText="Список пуст."
            :options="years"
            :canClear="false"
            @select="loadPreviousEventsData"
          />
        </div>
      </div>
      <div class="events__cards">
        <EventCard
          v-for="event in getPassportPrevEvents.events"
          :key="event.id"
          :event="event"
          theme="outline"
          @click="loadAgreements(event.agreements)"
        />
      </div>
      <div class="d-flex justify-content-center">
        <ButtonComponent v-if="isShowMorePrevEvent" class="events__show-btn" @click="loadPreviousEventsData">
          Показать еще
          <IconComponent name="plus-mini" class="events__show-btn-icon" />
        </ButtonComponent>
      </div>
    </div>
  </div>
  <ModalComponent v-model="eventAgreementsState" title="Соглашения" @close="eventAgreementsState = false">
    <div class="table-container-new" v-if="agreements?.length > 0">
      <table class="stockTable">
        <thead>
          <tr>
            <th class="agreement-table__name" @click="sortColumn('name')">
              <p>Наименование<span :class="getHeaderSortClass('name')"></span></p>
            </th>
            <th @click="sortColumn('money')">
              <p>Сумма (млн. руб)<span :class="getHeaderSortClass('money')"></span></p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agreement, index) in agreements" :key="index">
            <td>
              <p>{{ agreement.name }}</p>
            </td>
            <td>
              <p>{{ formatCurrency(agreement.money) }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else>По мероприятию не заключено ни одного соглашения</p>
  </ModalComponent>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import CustomLink from '@/common/components/CustomLink.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants.js';
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';
  import { EVENTS_PASSPORT_RELATION_LIMIT } from '../../utils/constants';
  import EventCard from '../EventCard.vue';

  export default {
    name: 'Events',
    mixins: [text],
    components: {
      Multiselect,
      IconComponent,
      ModalComponent,
      CustomLink,
      ButtonComponent,
      EventCard,
    },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      eventAgreementsState: false,
      agreements: [],
      year: null,
      sortedColumn: null,
      sortedOrder: 0,
      isShowMoreCurrentEvent: false,
      isShowMorePrevEvent: false,
      currentEventsLimit: 0,
      prevEventsLimit: 0,
    }),
    computed: {
      ...mapGetters({
        getPassportCurEvents: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportCurEvents,
        getPassportPrevEvents: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportPrevEvents,
        getPassportCurEventsCount: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportCurEventsCount,
        getPassportPrevEventsCount: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportPrevEventsCount,
      }),
    },
    watch: {
      regionId: {
        handler() {
          this.loadData();
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions({
        getPassportCurrentEvents: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportCurrentEvents,
        getPassportPreviousEvents: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPreviousEvents,
        getPassportCurrentEventsCount: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportCurrentEventsCount,
        getPassportPreviousEventsCount: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPreviousEventsCount,
      }),
      async loadData() {
        this.currentEventsLimit = 0;
        const currentDate = new Date();
        this.year = this.years.length > 0 ? this.years[this.years.length - 1].value : currentDate.getFullYear();
        await this.loadPreviousEventsData();

        await this.loadCurrentEventsData();
      },
      async loadCurrentEventsData() {
        const currentDate = new Date();
        this.loadCurrentEvents();

        const resCount = await this.getPassportCurrentEventsCount({
          regionId: this.regionId,
          year: currentDate.getFullYear(),
        });
        if (resCount.success) {
          this.isShowMoreCurrentEvent = this.getPassportCurEventsCount > this.currentEventsLimit;
        }
      },
      async loadCurrentEvents() {
        this.currentEventsLimit += EVENTS_PASSPORT_RELATION_LIMIT;
        const currentDate = new Date();
        await this.getPassportCurrentEvents({
          regionId: this.regionId,
          year: currentDate.getFullYear(),
          limit: this.currentEventsLimit,
        });
        this.isShowMoreCurrentEvent = this.getPassportCurEventsCount > this.currentEventsLimit;
      },

      async loadPreviousEventsData() {
        this.prevEventsLimit += EVENTS_PASSPORT_RELATION_LIMIT;

        await this.getPassportPreviousEvents({
          regionId: this.regionId,
          year: this.year,
          limit: this.prevEventsLimit,
        });

        const resCount = await this.getPassportPreviousEventsCount({
          regionId: this.regionId,
          year: this.year,
        });

        if (resCount.success) {
          this.isShowMorePrevEvent = this.getPassportPrevEventsCount > this.prevEventsLimit;
        }
      },
      getHeaderSortClass(id) {
        if (this.sortedColumn === id) {
          return this.sortedOrder === 0 ? 'events__header-asc' : 'events__header-des';
        }
        return 'events__header-default';
      },
      sortColumn(id) {
        if (this.sortedColumn === id) {
          this.sortedOrder = this.sortedOrder === 0 ? 1 : 0;
        } else {
          this.sortedColumn = id;
          this.sortedOrder = 0;
        }

        this.agreements =
          this.sortedOrder === 0
            ? this.agreements.sort((a, b) => (a[id] < b[id] ? -1 : 1))
            : this.agreements.sort((a, b) => (a[id] > b[id] ? -1 : 1));
      },
      loadAgreements(data) {
        this.agreements = data;
        this.eventAgreementsState = true;
      },
      formatCurrency(value) {
        return this.formatNumber(value / Constants.THOUSAND, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryАnalytics/styles/countryAnalyticsMultiselect.scss';

  .events {
    &__header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 20px;
    }
    &__header {
      font: $fira-20;
      span {
        color: $blue;
      }
    }
    &__year-select {
      width: 150px;
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 520px), 1fr));
      gap: 8px;
    }
    &__show-btn {
      display: flex;
      align-items: center;
      font-family: 'Fira Sans';
      font-size: 15px;
      line-height: 20px;
      color: $green;
      cursor: pointer;
      margin-top: 15px;
      background: unset;
    }

    &__show-btn-icon {
      width: 14px;
      margin-left: 5px;
    }

    &__header-default {
      position: absolute;
      content: '';
      margin-left: 4px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='20' viewBox='0 0 16 22' fill='none'%3E%3Cpath d='M12 10L8 6L4 10' stroke='%23989898' stroke-width='2' stroke-linecap='square'/%3E%3Cpath d='M4 14L8 18L12 14' stroke='%23989898' stroke-width='2' stroke-linecap='square' transform='translate(0, 2)'/%3E%3C/svg%3E");
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      width: 16px;
      height: 20px;
      border: none;
      bottom: 3px;
    }

    &__header-asc,
    &__header-des {
      position: absolute;
      content: '';
      top: 10%;
      margin-left: 4px;
      background: url(~@/assets/images/svg/chevronDownSort.svg);
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
    }

    &__header-des {
      transform: rotate(180deg);
      top: 0 !important;
    }
  }
  .agreement-table__name {
    width: 420px;
  }
  .select-country-analytic::v-deep {
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>
