<template>
  <div class="trade-turnover">
    <div class="trade-turnover__select-wrapper">
      <div class="trade-turnover__year-select">
        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="years"
          :canClear="false"
          @select="loadTradeTurnover"
        />
      </div>
    </div>
    <div class="trade-turnover__wrapper">
      <div v-for="indicator in indicators" :key="indicator.id">
        <IndicatorCard v-bind="indicator" modContentTooltip="width-250" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import IndicatorCard from '@/common/components/redesigned/IndicatorCard.vue';
  import text from '@/common/mixins/text';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';

  export default {
    name: 'TradeTurnover',
    mixins: [text],
    components: {
      IndicatorCard,
      Multiselect,
    },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      indicators: [],
      year: null,
    }),
    computed: {
      ...mapGetters({
        getPassportTradeTurnovers: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportTradeTurnovers,
      }),
    },
    watch: {
      regionId: {
        handler() {
          this.loadTradeTurnover();
        },
      },
    },
    created() {
      const currentDate = new Date();
      this.year = this.years.length > 0 ? this.years[this.years.length - 1].value : currentDate.getFullYear();
      this.loadTradeTurnover();
    },
    methods: {
      ...mapActions({
        getPassportTradeTurnover: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportTradeTurnover,
      }),

      async loadTradeTurnover() {
        const response = await this.getPassportTradeTurnover({
          regionId: this.regionId,
          year: this.year,
        });
        if (response.success) {
          this.buildTradeTurnover(this.getPassportTradeTurnovers);
          return;
        }
        this.buildTradeTurnover({});
      },

      buildTradeTurnover(data) {
        const {
          allCountriesTurnover = {},
          cisTurnover = {},
          eaeuTurnover = {},
          farAbroadTurnover = {},
          unfriendlyTurnover = {},
        } = data;

        this.indicators = [
          {
            id: 1,
            title: 'Со всеми странами',
            value: allCountriesTurnover?.currentValue ? this.formatNumber(allCountriesTurnover.currentValue, 1) : 0,
            percentValue: allCountriesTurnover?.diff ? this.formatNumber(allCountriesTurnover?.diff, 1) : 0,
            year: allCountriesTurnover?.year ?? '-',
            valueMeasure: 'млн $',
          },
          {
            id: 2,
            title: 'Со странами СНГ',
            value: cisTurnover?.currentValue ? this.formatNumber(cisTurnover.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: cisTurnover?.diff ? this.formatNumber(cisTurnover?.diff, 1) : 0,
            year: cisTurnover?.year ?? '-',
          },
          {
            id: 3,
            title: 'Со странами ЕАЭС',
            value: eaeuTurnover?.currentValue ? this.formatNumber(eaeuTurnover.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: eaeuTurnover?.diff ? this.formatNumber(eaeuTurnover?.diff, 1) : 0,
            year: eaeuTurnover?.year ?? '-',
          },
          {
            id: 4,
            title: 'Со странами дальнего зарубежья',
            value: farAbroadTurnover?.currentValue ? this.formatNumber(farAbroadTurnover.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: farAbroadTurnover?.diff ? this.formatNumber(farAbroadTurnover?.diff, 1) : 0,
            year: farAbroadTurnover?.year ?? '-',
            tooltipText: 'За исключением стран СНГ.',
          },
          {
            id: 5,
            title: 'С недружественными странами',
            value: unfriendlyTurnover?.currentValue ? this.formatNumber(unfriendlyTurnover.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: unfriendlyTurnover?.diff ? this.formatNumber(unfriendlyTurnover?.diff, 1) : 0,
            year: unfriendlyTurnover?.year ?? '-',
            titleStyle: { color: '#DC3545' },
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryАnalytics/styles/countryAnalyticsMultiselect.scss';

  .trade-turnover {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    &__select-wrapper {
      display: flex;
      justify-content: end;
    }
    &__year-select {
      width: 150px;
      margin-bottom: 20px;
    }
  }
  .m-right-5 {
    margin-right: 5px;
  }
  .select-country-analytic::v-deep {
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>

<style lang="scss">
  .trade-turnover {
    .indicator-card {
      width: 240px !important;
    }
  }
</style>
