<template>
  <div class="efficiency-indicators">
    <div class="efficiency-indicators__select-wrapper">
      <div class="efficiency-indicators__year-select">
        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="years"
          :canClear="false"
          @select="loadKeyIndicators"
        />
      </div>
    </div>

    <div class="efficiency-indicators__wrapper">
      <div v-for="indicator in indicators" :key="indicator.id">
        <IndicatorCard v-bind="indicator">
          <template v-slot:tooltipContent>
            <TooltipContentEfficiencyIndex />
          </template>
        </IndicatorCard>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import IndicatorCard from '@/common/components/redesigned/IndicatorCard.vue';
  import text from '@/common/mixins/text';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';
  import TooltipContentEfficiencyIndex from '../informationEconomic/TooltipContentEfficiencyIndex';

  export default {
    name: 'KeyIndex',
    mixins: [text],
    components: {
      IndicatorCard,
      TooltipContentEfficiencyIndex,
      Multiselect,
    },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      indicators: [],
      year: null,
    }),
    computed: {
      ...mapGetters({
        getPassportKeys: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportKeys,
      }),
    },
    watch: {
      regionId: {
        handler() {
          this.loadKeyIndicators();
        },
      },
    },
    created() {
      const currentDate = new Date();
      this.year = this.years.length > 0 ? this.years[this.years.length - 1].value : currentDate.getFullYear();
      this.loadKeyIndicators();
    },
    methods: {
      ...mapActions({
        getPassportKeyData: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportKeyData,
      }),

      async loadKeyIndicators() {
        await this.getPassportKeyData({ regionId: this.regionId, year: this.year });

        const {
          efficiencyIndex,
          eventsCount,
          agreementsDoneCount,
          agreementsSum,
          export: keyExport,
          import: keyImport,
          nonEnergyExport,
          importPartnerCount,
          exportPartnerCount,
        } = this.getPassportKeys;

        this.indicators = [
          {
            id: 1,
            title: 'Рейтинг эффективности',
            value: efficiencyIndex?.efficiencyIndex ?? 0,
            place: efficiencyIndex?.place,
            year: efficiencyIndex?.year ?? '-',
            tooltipText: ' Текст для тултипа ',
          },
          {
            id: 2,
            title: 'Количество мероприятий в сфере ВЭС',
            value: eventsCount?.currentValue ?? 0,
            valueMeasure: '',
            percentValue: eventsCount?.diff ?? 0,
            isPercent: false,
            year: eventsCount?.year ?? '-',
          },
          {
            id: 3,
            title: 'Заключенные соглашения',
            value: agreementsDoneCount?.currentValue ?? 0,
            valueMeasure: '',
            percentValue: agreementsDoneCount?.diff ?? 0,
            isPercent: false,
            year: agreementsDoneCount?.year ?? '-',
          },
          {
            id: 4,
            title: 'Стоимостная оценка соглашений',
            value: agreementsSum?.currentValue ? this.formatNumber(agreementsSum.currentValue, 1) : 0,
            valueMeasure: 'млн руб',
            percentValue: agreementsSum?.diff ?? 0,
            isPercent: true,
            year: agreementsSum?.year ?? '-',
          },
          {
            id: 5,
            title: 'Экспорт',
            value: keyExport?.currentValue ? this.formatNumber(keyExport.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: keyExport?.diff ?? 0,
            isPercent: true,
            year: keyExport?.year ?? '-',
          },
          {
            id: 6,
            title: 'Импорт',
            value: keyImport?.currentValue ? this.formatNumber(keyImport.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: keyImport?.diff ?? 0,
            isPercent: true,
            year: keyImport?.year ?? '-',
          },
          {
            id: 7,
            title: 'Несырьевой неэнергетический экспорт',
            value: nonEnergyExport?.currentValue ? this.formatNumber(nonEnergyExport.currentValue, 1) : 0,
            valueMeasure: 'млн $',
            percentValue: nonEnergyExport?.diff ?? 0,
            isPercent: true,
            year: nonEnergyExport?.year ?? '-',
          },
          {
            id: 8,
            title: 'Количество стран-партнёров по экспорту',
            value: importPartnerCount?.currentValue ?? 0,
            valueMeasure: '',
            percentValue: importPartnerCount?.diff ?? 0,
            isPercent: false,
            year: importPartnerCount?.year ?? '-',
          },
          {
            id: 9,
            title: 'Количество стран-партнёров по импорту',
            value: exportPartnerCount?.currentValue ?? 0,
            valueMeasure: '',
            percentValue: exportPartnerCount?.diff ?? 0,
            isPercent: false,
            year: exportPartnerCount?.year ?? '-',
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryАnalytics/styles/countryAnalyticsMultiselect.scss';

  .efficiency-indicators {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    &__select-wrapper {
      display: flex;
      justify-content: end;
    }
    &__year-select {
      width: 150px;
      margin-bottom: 20px;
    }
  }
  .m-right-5 {
    margin-right: 5px;
  }
  .select-country-analytic::v-deep {
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>

<style lang="scss">
  .efficiency-indicators {
    .indicator-card {
      width: 240px !important;
    }
  }
</style>
