<template>
  <div class="partner-countries">
    <div class="partner-countries__header-wrapper">
      <p class="partner-countries__header">
        Общее количество стран-партнеров<span>{{ getPassportPartnersCount }}</span>
      </p>
      <div class="partner-countries__year-select">
        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="years"
          :canClear="false"
          @select="loadData"
        />
      </div>
    </div>
    <div>
      <TopCountryStatisticChart
        title="Товарооборот, млн $"
        :seriesProp="turnoverSeriesData"
        :categories="[]"
        :options="options"
        :isShowLegend="true"
        :height="resolveHeight(turnoverSeriesData?.labels)"
      />
    </div>
    <div class="mt-24">
      <TopCountryStatisticChart
        title="Экспорт, млн. $"
        :seriesProp="exportSeriesData"
        :categories="[]"
        :options="options"
        :isShowLegend="true"
        :height="resolveHeight(exportSeriesData?.labels)"
      />
    </div>
    <div class="mt-24">
      <TopCountryStatisticChart
        title="Импорт, млн. $"
        :seriesProp="importSeriesData"
        :categories="[]"
        :options="options"
        :isShowLegend="true"
        :height="resolveHeight(importSeriesData?.labels)"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import text from '@/common/mixins/text';

  import TopCountryStatisticChart from '@/components/ChartComponents/TopCountryStatisticChart';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';

  export default {
    name: 'PartnerCountries',
    mixins: [text],
    components: { TopCountryStatisticChart, Multiselect },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      importSeriesData: {},
      exportSeriesData: {},
      turnoverSeriesData: {},
      year: null,
    }),

    computed: {
      ...mapGetters({
        getPassportPartnerCountries: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportPartnerCountries,
        getPassportPartnersCount: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportPartnersCount,
      }),

      options() {
        return {
          indexAxis: 'y',
          locale: 'ru-RU',
          clip: false,
          layout: {
            padding: {
              right: 70,
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
              external: function (context) {
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // создать при первом рендере
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<div></div>';
                  document.body.appendChild(tooltipEl);
                }

                // скрыть, если нет тултипа
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // установить позицию
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                // установить наполнение (текст)
                if (tooltipModel.body) {
                  const { dataset, dataIndex } = tooltipModel.dataPoints[0];

                  const header = dataset.header;
                  const pref = dataset.years[dataIndex];
                  const post = dataset.percents[dataIndex].toFixed(1);
                  const postParts = post.split('.');
                  const postNumber = `${postParts[0]},${postParts.length > 1 ? postParts[1] : 0}`;
                  const body = `${dataset.dataLabels[dataIndex]}`;
                  const partOfAllValue = dataset.partOfAllValue[dataIndex];

                  let innerHtml = `<div class="custom-chart-tooltip custom-chart-tooltip--width-220">
                <p class="mb-8">${header}</p>
                <div class="d-flex">
                <span class="circle" style="background: ${dataset.backgroundColor}"></span>`;
                  innerHtml += `<p>${pref}: ${body} (${partOfAllValue}%)`;
                  innerHtml +=
                    post > 0 ? `<span class="text-green"> +${postNumber}%` : `<span class="text-red"> ${postNumber}%`;
                  innerHtml += '</span></p></div></div>';

                  let tableRoot = tooltipEl.querySelector('div');
                  tableRoot.innerHTML = innerHtml;
                }

                const position = context.chart.canvas.getBoundingClientRect();

                // установка стилей, позиции
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX / 2 + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 60 + 'px';
                tooltipEl.style.cursor = 'pointer';
                tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                tooltipEl.style.pointerEvents = 'none';
              },
            },
            legend: {
              display: false,
            },
            datalabels: {
              clamp: true,
              align: 'end',
              offset: 0,
              anchor: 'end',
              color: '#231F20',
              family: 'Fira Sans',
              textAlign: 'end',

              font: {
                fontSize: 14,
                weight: 'bold',
              },
              formatter: (value) => {
                const parts = value.toFixed(1).split('.');
                const numberPart = parts[0];
                const decimalPart = parts[1];
                const thousands = /\B(?=(\d{3})+(?!\d))/g;
                return `${numberPart.replace(thousands, ' ')},${decimalPart}`;
              },
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 16,
                },
                color: '#000',
                crossAlign: 'far',
              },
            },
          },
        };
      },
    },
    watch: {
      regionId: {
        handler() {
          this.loadData();
        },
      },
    },
    created() {
      const currentDate = new Date();
      this.year = this.years.length > 0 ? this.years[this.years.length - 1].value : currentDate.getFullYear();
      this.loadData();
    },
    methods: {
      ...mapActions({
        getPassportPartnerCountriesData: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPartnerCountriesData,
        getPassportPartnerCountriesCount: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportPartnerCountriesCount,
      }),
      async loadData() {
        const currentDate = new Date();
        const response = await this.getPassportPartnerCountriesData({
          regionId: this.regionId,
          year: this.year,
        });

        if (response.success) {
          this.importSeriesData = this.buildSeriesData({
            data: this.getPassportPartnerCountries.import,
            header: 'Импорт',
          });
          this.exportSeriesData = this.buildSeriesData({
            data: this.getPassportPartnerCountries.export,
            header: 'Экспорт',
          });
          this.turnoverSeriesData = this.buildSeriesData({
            data: this.getPassportPartnerCountries.turnover,
            header: 'Товарооборот',
          });
        }

        await this.getPassportPartnerCountriesCount({ regionId: this.regionId, year: currentDate.getFullYear() });
      },
      buildSeriesData({ data, header }) {
        if (data == null || data.length === 0) {
          return { datasets: [], labels: [] };
        }
        return {
          datasets: [
            {
              data: data.map(({ currentTradeData }) => Number(currentTradeData?.currentValue ?? 0)),
              dataLabels: data.map(({ currentTradeData }) => this.formatNumber(currentTradeData?.currentValue ?? 0, 1)),
              backgroundColor: '#01A39D',
              label: data[0].currentTradeData?.year ?? '',
              years: data.map(({ currentTradeData }) => currentTradeData?.year),
              percents: data.map(({ currentTradeData }) => Number(currentTradeData?.diff ?? 0)),
              header: header,
              partOfAllValue: data.map(({ partOfAllValue }) => this.formatNumber(partOfAllValue ?? 0, 0)),
              barPercentage: '1',
            },
            {
              data: data.map(({ previousYearValue }) => Number(previousYearValue ?? 0)),
              dataLabels: data.map(({ previousYearValue }) => this.formatNumber(previousYearValue ?? 0, 1)),
              backgroundColor: '#D9D9D9',
              label: data[0].currentTradeData?.year ? data[0].currentTradeData?.year - 1 : '',
              years: data.map(({ currentTradeData }) => (currentTradeData?.year ? currentTradeData?.year - 1 : '')),
              percents: data.map(({ currentTradeData }) => Number(currentTradeData?.diff ?? 0)),
              header: header,
              partOfAllValue: data.map(({ partOfAllValue }) => this.formatNumber(partOfAllValue ?? 0, 0)),
              barPercentage: '1',
            },
          ],
          labels: data.map((el, index) => `${index + 1} ${el.country}`),
        };
      },

      resolveHeight(labels) {
        if (labels == null) {
          return 60;
        }
        return labels.length * 60;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryАnalytics/styles/countryAnalyticsMultiselect.scss';

  .partner-countries {
    &__header-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__year-select {
      width: 150px;
      margin-bottom: 20px;
    }
    &__header {
      color: $base;
      font: $fira-20-M;

      span {
        margin-left: 5px;
        color: $green;
        font: $fira-20-M;
      }
    }
  }
  .select-country-analytic::v-deep {
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>

<style lang="scss">
  @import '@/components/ChartComponents/styles/custom-tooltip.scss';

  .partner-countries {
    .bar-chart-tooltip__legend-point {
      border-radius: 50%;
    }
    .bar-chart-tooltip__legend-name {
      font-size: 15px !important;
    }
    h4 {
      font: $fira-20-M !important;
    }
  }
</style>
