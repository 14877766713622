<template>
  <div class="common-info">
    <div class="common-info__select-wrapper">
      <div class="common-info__year-select">
        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="years"
          :canClear="false"
          @select="loadCommonInformation"
        />
      </div>
    </div>
    <div class="mb-20">
      <div v-for="item in information" :key="item.id" class="common-info__info-wrapper">
        <p class="common-info__label">{{ item.label }}</p>
        <template v-if="item?.isArray">
          <ol v-if="item.description && item.description.length > 0">
            <li v-for="(desc, index) in item.description" :key="index" class="common-info__description">
              {{ index + 1 }}. {{ desc }}
            </li>
          </ol>
          <p v-else>-</p>
        </template>

        <p v-else class="common-info__description">
          {{ item.description }} <span v-if="item.id === 2">км<sup>2</sup></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import text from '@/common/mixins/text';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';

  export default {
    name: 'CommonInfo',
    mixins: [text],
    components: {
      Multiselect,
    },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      information: [],
      year: null,
    }),
    watch: {
      regionId: {
        handler() {
          this.loadCommonInformation();
        },
      },
    },
    created() {
      const currentDate = new Date();
      this.year = this.years.length > 0 ? this.years[this.years.length - 1].value : currentDate.getFullYear();
      this.loadCommonInformation();
    },
    computed: {
      ...mapGetters({
        getPassportMainInformation: ECONOMIC_RELATIONS_GETTERS_NAME.getPassportMainInformation,
      }),
    },
    methods: {
      ...mapActions({
        getPassportMainData: ECONOMIC_RELATIONS_ACTIONS_NAME.getPassportMainData,
      }),
      async loadCommonInformation() {
        await this.getPassportMainData({ regionId: this.regionId, year: this.year });
        const {
          capital = '',
          enterprisesWithForeighCapital = 0,
          grossRegionalDomesticProduct = 0,
          locationType = '-',
          municipalEntitiesCount = 0,
          neighbourCountries = '-',
          population = 0,
          sectors = '-',
          totalArea = 0,
        } = this.getPassportMainInformation;

        this.information = [
          { id: 1, label: 'Административный центр', description: capital },
          { id: 2, label: 'Общая площадь', description: this.formatNumber(totalArea, 0) },
          { id: 3, label: 'Численность населения', description: `${this.formatNumber(population, 0)} чел.` },
          {
            id: 4,
            label: 'Количество муниципальных образований на территории субъекта РФ',
            description: this.formatNumber(municipalEntitiesCount, 0),
          },
          {
            id: 5,
            label: 'Валовый региональный продукт (ВРП)',
            description: `${this.formatNumber(grossRegionalDomesticProduct, 1)} млрд. руб.`,
          },
          { id: 6, label: 'Приграничное положение субъекта', description: locationType },
          { id: 7, label: 'Приграничные государства', description: neighbourCountries, isArray: true },
          {
            id: 8,
            label: 'Количество предприятий с участием иностранного капитала',
            description: `${this.formatNumber(enterprisesWithForeighCapital, 0)} шт.`,
          },
          { id: 9, label: 'Основные отрасли экономики', description: sectors, isArray: true },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryАnalytics/styles/countryAnalyticsMultiselect.scss';

  .common-info {
    &__info-wrapper {
      display: flex;
      gap: 15px;
      padding: 20px 0;
      border-bottom: 1px solid $light-grey-11;
      width: 80%;
    }
    &__label {
      width: 40%;
      font-family: 'Fira Sans';
      font-size: 15px;
      font-weight: 500;
    }
    &__description {
      font-family: 'Fira Sans';
      font-size: 15px;
    }
    &__donut-title {
      font: $fira-20-M;
      color: $base;
      margin-bottom: 10px;
    }
    &__donut-wrapper {
      display: inline-block;
      border: 1px solid #bbc5ca;
    }
    &__donut-graph {
      width: 400px;
      height: 300px;
      padding-top: 30px;
    }
    &__select-wrapper {
      display: flex;
      justify-content: end;
    }
    &__year-select {
      width: 150px;
      margin-bottom: 20px;
    }
  }
  .select-country-analytic::v-deep {
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>
