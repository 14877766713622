<template>
  <div class="responsible-organ">
    <div class="responsible-organ__select-wrapper">
      <div class="responsible-organ__year-select">
        <Multiselect
          class="select-country-analytic"
          label="label"
          :searchable="true"
          v-model="year"
          valueProp="value"
          track-by="label"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="years"
          :canClear="false"
          @select="loadData"
        />
      </div>
    </div>
    <div class="responsible-organ__item mb-20">
      <p class="responsible-organ__item-header">Орган</p>
      <p class="responsible-organ__item-info">{{ responsibleOrganInfo.organName }}</p>
    </div>
    <div class="responsible-organ__item">
      <p class="responsible-organ__item-header">Заместитель высшего должностного лица</p>
      <div class="responsible-organ__deputy-wrapper">
        <div class="responsible-organ__deputy">
          <p class="responsible-organ__deputy-name">{{ responsibleOrganInfo.name }}</p>
          <p class="responsible-organ__deputy-position">{{ responsibleOrganInfo.position }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import { ECONOMIC_RELATIONS_ACTIONS_NAME, ECONOMIC_RELATIONS_GETTERS_NAME } from '../../store/economicRelations/name';

  export default {
    name: 'ResponsibleOrgan',
    components: {
      Multiselect,
    },
    props: {
      regionId: {
        type: Number,
        required: true,
      },
      years: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      responsibleOrganInfo: {
        organName: '',
        name: '',
        position: '',
        year: null,
      },
    }),
    computed: {
      ...mapGetters({
        getResponsibleAuthorityInfo: ECONOMIC_RELATIONS_GETTERS_NAME.getResponsibleAuthorityInfo,
      }),
    },
    watch: {
      regionId: {
        handler() {
          this.loadData();
        },
      },
    },
    created() {
      const currentDate = new Date();
      this.year = this.years.length > 0 ? this.years[this.years.length - 1].value : currentDate.getFullYear();
      this.loadData();
    },
    methods: {
      ...mapActions({
        getResponsibleAuthority: ECONOMIC_RELATIONS_ACTIONS_NAME.getResponsibleAuthority,
      }),

      async loadData() {
        const response = await this.getResponsibleAuthority({
          regionId: this.regionId,
          year: this.year,
        });
        if (response.success) {
          const deputy = this.getResponsibleAuthorityInfo?.deputySeniorOfficial.split(',');
          this.responsibleOrganInfo = {
            organName: this.getResponsibleAuthorityInfo?.executiveAuthority ?? '',
            name: deputy.length > 0 ? deputy[0] : '',
            position: deputy.length >= 2 ? deputy[1] : '',
          };
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryАnalytics/styles/countryAnalyticsMultiselect.scss';

  .responsible-organ {
    &__item {
      display: flex;
      padding: 25px;
      background: $light-grey;
      border: 1px solid $grey;
      &-header {
        font: $fira-20;
        max-width: 40%;
        min-width: 40%;
      }
      &-info {
        font: $fira-20-M;
      }
    }
    &__deputy-wrapper {
      display: flex;
    }
    &__deputy {
      &-name {
        font: $fira-20-M;
        margin-bottom: 10px;
      }
      &-position {
        color: $light-grey-12;
        font: $fira-14;
      }
    }
    &__select-wrapper {
      display: flex;
      justify-content: end;
    }
    &__year-select {
      width: 150px;
      margin-bottom: 20px;
    }
  }
  .select-country-analytic::v-deep {
    input:focus {
      border: unset;
      border-bottom: 1px solid $color8;
    }
  }
</style>
