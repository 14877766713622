<template>
  <div class="event-card" :class="`event-card--theme-${theme}`">
    <p class="event-card__date">{{ getEventDate }}</p>
    <div class="event-card__content">
      <p class="event-card__name" v-if="event.name">{{ event.name }}</p>
      <div class="event-card__info">
        <p class="event-card__place">{{ event.city }}</p>
        <p class="event-card__argeements" v-if="event.agreementsSum">
          Соглашений заключено на сумму {{ formatCurrency(event.agreementsSum) }} млн рублей
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants.js';
  import text from '@/common/mixins/text';

  export default {
    name: 'EventCard',

    mixins: [text],

    props: {
      event: {
        type: Object,
        required: true,
      },

      theme: {
        type: String,
        default: 'outline',
        validator(value) {
          return ['outline', 'fill'].includes(value);
        },
      },
    },

    methods: {
      formatDate(date) {
        return this.$momentFormat(date, Constants.DATE_FORMATS.DD_MMMM_YYYY);
      },

      formatCurrency(value) {
        return this.formatNumber(value / Constants.THOUSAND, 1);
      },
    },

    computed: {
      getEventDate() {
        const startDate = this.formatDate(this.event.startDate).replaceAll(' ', '\u00A0');
        if (!this.event.endDate || this.event.startDate === this.event.endDate) {
          return startDate;
        }
        return `${startDate} - ${this.formatDate(this.event.endDate).replaceAll(' ', '\u00A0')}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .event-card {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: start;
    padding: 16px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      inset: 0;
      border-radius: 4px;
    }
  }

  .event-card__date {
    flex: 0 0 180px;
    font: $fira-20-M;
    color: $base;

    @media (max-width: $mobile) {
      flex: 0 0 100%;
    }
  }

  .event-card__content {
    flex: 1 1 270px;
    font: $fira-16;
  }

  .event-card__name {
    color: $base;
    font-family: inherit;
  }

  .event-card__info {
    font-size: 14px;
    font-family: inherit;
    color: $light-grey-12;
  }

  .event-card__place,
  .event-card__argeements {
    font-family: inherit;
  }

  .event-card--theme-fill {
    &::before {
      opacity: 0.08;
      background-color: $green-2;
    }
  }

  .event-card--theme-outline {
    &::before {
      background: $light-grey;
      border: 1px solid $grey;
    }
  }
</style>
